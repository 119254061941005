import { Component } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'
import WebsiteMember from './WebsiteMember.vue'
import WebsiteRating from './WebsiteRating.vue'
import WebsiteTag from './WebsiteTag.vue'

@Component({
  components: {
    WebsiteMember,
    WebsiteRating,
    WebsiteTag
  }
})
export default class WebsiteReviewerPreview extends BaseMixin {

}
