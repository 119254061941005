import { Component } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'
import { SET_META } from 'booksprout-app'

import IconChatBubble from '../../../components/svg-icons/IconChatBubble.vue'
import WebsiteButton from '../../../components/WebsiteButton.vue'
import WebsiteCard from '../../../components/WebsiteCard.vue'
import WebsiteMember from '../../../components/WebsiteMember.vue'
import WebsiteMemberCarousel from '../../../components/WebsiteMemberCarousel.vue'
import WebsiteRating from '../../../components/WebsiteRating.vue'
import WebsiteReviewPopup from '../../../components/WebsiteReviewPopup.vue'
import WebsiteReviewerPreview from '../../../components/WebsiteReviewerPreview.vue'
import WebsiteSpinner from '../../../components/WebsiteSpinner.vue'

@Component({
  components: {
    IconChatBubble,
    WebsiteButton,
    WebsiteCard,
    WebsiteMember,
    WebsiteMemberCarousel,
    WebsiteRating,
    WebsiteReviewPopup,
    WebsiteReviewerPreview,
    WebsiteSpinner
  }
})
export default class WebsiteIndexPage extends BaseMixin {
  showSpinner = true

  reviewCount = 3800000
  reviewUserCount = 70000
  authorCount = 40000

  created () {
    const title = 'Get more book reviews'
    const canonicalUrl = window.location.origin + this.websitePaths.home

    void this.$store.dispatch(SET_META, {
      title: title,
      description: 'Automate your ARC process and get more honest book reviews on sites like Amazon.',
      ogTitle: title,
      ogUrl: canonicalUrl,
      ogImage: window.location.origin + '/cdn/website/honest-reviews.png',
      canonicalUrl
    })
  }

  mounted () {
    this.showSpinner = false
    this.getWebsiteStats().then(stats => {
      this.showSpinner = false
      this.reviewCount = stats.arcReviewCount
      this.reviewUserCount = stats.arcReviewUserCount
      this.authorCount = stats.authorCount
    })
  }
}
