import { Component} from 'vue-property-decorator'
import { BaseMixin } from './mixins/base'
import WebsiteMember from './WebsiteMember.vue'

interface Member {
  avatar: string
  name: string
  freeText: string,
  isHighlighted?: boolean
}

@Component({
  components: {
    WebsiteMember
  }
})
export default class WebsiteMemberCarousel extends BaseMixin {
  members: Member[] = []

  created () {
    this.members.push({
      avatar: '/cdn/website/avatars/dustin-edwards-avatar.png',
      name: 'Dustin Edwards',
      freeText: '46 books reviewed'
    }, {
      avatar: '/cdn/website/avatars/bessie-fox-avatar.png',
      name: 'Bessie Fox',
      freeText: '62 books reviewed',
      isHighlighted: true
    }, {
      avatar: '/cdn/website/avatars/annette-simmons-avatar.png',
      name: 'Annette Simmons',
      freeText: '26 books reviewed'
    })
  }
}
